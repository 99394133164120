export const lowIncomeHighPotentialCountryCodes = [
  "TR", // Turkey
  // "IN", // India
  "MX", // Mexico
  "ID", // Indonesia
  "VN", // Vietnam
  "BD", // Bangladesh
  "PK", // Pakistan
  "NG", // Nigeria
];
