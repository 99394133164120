import {
  Box,
  Container,
  Heading,
  Icon,
  SimpleGrid,
  Square,
  Stack,
  Text,
} from "@chakra-ui/react";
import { AiFillAudio } from "react-icons/ai";
import { BsStars } from "react-icons/bs";
import { HiLanguage } from "react-icons/hi2";
import { MdLanguage } from "react-icons/md";
import { TiMessages } from "react-icons/ti";
import { FaChalkboardTeacher, FaFireAlt } from "react-icons/fa";
import { RiTranslate } from "react-icons/ri";
import { FiBook } from "react-icons/fi";

export const Features = () => (
  <Box as="section" bg="bg.surface">
    <Container py={{ base: "16", md: "24" }}>
      <Stack spacing={{ base: "12", md: "16" }}>
        <Stack
          spacing={{ base: "4", md: "5" }}
          align="center"
          textAlign="center"
        >
          <Stack spacing="3">
            <Heading size={{ base: "sm", md: "md" }}>Feature Spotlight</Heading>
          </Stack>
          <Text color="fg.muted" fontSize={{ base: "lg", md: "xl" }} maxW="3xl">
            Navigate our suite of features, designed to turn your language
            learning into an immersive and engaging journey
          </Text>
        </Stack>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }}
          columnGap={8}
          rowGap={{ base: 10, md: 16 }}
        >
          {features.map((feature) => (
            <Stack
              key={feature.name}
              spacing={{ base: "4", md: "5" }}
              align="center"
              textAlign="center"
            >
              <Square
                size={{ base: "10", md: "12" }}
                bg="brand.primary.main"
                color="fg.inverted"
                borderRadius="lg"
              >
                <Icon as={feature.icon} boxSize={{ base: "5", md: "6" }} />
              </Square>
              <Stack spacing={{ base: "1", md: "2" }}>
                <Text fontSize={{ base: "lg", md: "xl" }} fontWeight="medium">
                  {feature.name}
                </Text>
                <Text color="fg.muted">{feature.description}</Text>
              </Stack>
            </Stack>
          ))}
        </SimpleGrid>
      </Stack>
    </Container>
  </Box>
);

const features = [
  {
    name: "Voice Conversations",
    description: "Have engaging voice conversations with our AI tutors",
    icon: TiMessages,
  },
  {
    name: "Pronunciation",
    description:
      "Assess and practice your pronunciation with word-by-word feedback",
    icon: AiFillAudio,
  },
  {
    name: "Feedback",
    description:
      "Get instant feedback on your language skills, and tips on how to improve",
    icon: FaChalkboardTeacher,
  },
  {
    name: "Multiple Languages and Dialects",
    description:
      "Choose between 134 languages and dialects to practice your speaking skills",
    icon: MdLanguage,
  },
  {
    name: "Topics",
    description:
      "Choose from a wide range of topics to discuss with our AI tutors",
    icon: BsStars,
  },
  {
    name: "Wordbook",
    description: "Save and revisit words learned during training",
    icon: FiBook,
  },
  {
    name: "Streaks",
    description:
      "Keep track of your progress with streaks to built a habit of learning",
    icon: FaFireAlt,
  },
  {
    name: "Crosstalk",
    description:
      "Communicate with the tutor in both your native language and the language you are learning",
    icon: RiTranslate,
  },
  {
    name: "Translations",
    description: "Instant translations to support you in your conversations",
    icon: HiLanguage,
  },
];
