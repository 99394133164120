import { HStack, Icon, List, ListItem, Text } from "@chakra-ui/react";
import { FiCheck } from "react-icons/fi";

interface Props {
  color?: string;
  checkMarkColor?: string;
  my?: number;
  fontSize?: string;
  features?: string[];
  spacing?: number;
  listItemSpacing?: number;
}

export default function FeatureCheckList(props: Props) {
  const features = props.features || [
    "Life-like conversations with AI tutors",
    "Pronunciation practice tool",
    "Detailed feedback on your speaking skills",
    "Interactive wordbook",
    "Supports 40+ languages",
  ];

  return (
    <List my={props.my || 8} id="feature-checklist" color={props.color}>
      {features.map((feature, i) => (
        <ListItem key={i} mb={props.listItemSpacing}>
          <HStack spacing={props.spacing || 4}>
            <Icon
              as={FiCheck}
              color={props.checkMarkColor || "brand.green.400"}
              boxSize="6"
            />
            <Text fontSize={props.fontSize || "sm"} textAlign="left">
              {feature}
            </Text>
          </HStack>
        </ListItem>
      ))}
    </List>
  );
}
