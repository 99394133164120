import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Text,
  Avatar,
  Button,
} from "@chakra-ui/react";
import { StdLink } from "./StdLink";

const faqItems = [
  [
    "Is there a free plan?",
    `
    No, all plans are paid. However, if you choose the subscription option, there's a 7-day free trial that can be cancelled anytime. 
    No strings attached.`,
  ],
  ["Who is behind Talkio AI?", () => <FaqMe />],
  ["Is Talkio AI suitable for beginners", () => <GoodForBeginners />],
  [
    "Is payment secure?",
    `Yes, we use Stripe for financial processing. We do not store any of your credit card information.`,
  ],
  [
    "Where is my data stored?",
    `Your data is stored on servers in the European Union: 
      Microsoft (voice and data), Auth0 (authentication), Stripe (payments).
      Furthermore, we use services from OpenAI, Anthropic and Groq to power the AI. 
      This data is processed in the United States.
    `,
  ],
  [
    "Can I get a refund?",
    `The free trial can be canceled at any time; however, if you forget to cancel the trial,
     we can refund you within 14 days, provided you haven't used the 
     service after the trial ended. For one-time purchases, there is a 7-day full 
     refund policy. After that, the same terms apply as for subscriptions.
    Please contact us at support@talkio.ai
    `,
  ],
  [
    "What payment methods do you accept?",
    `For subscriptions we accept all major credit cards and Paypal. 
    For one-time purchases we also accept WeChat, Przelewy24, Giropay, Alipay and more. 
    Availability depends on your location.
    `,
  ],
  [
    "Does Talkio have a mobile app?",
    `Talkio is a Progressive Web App meaning you can install it from the browser without going to the App Store or Google Play. 
    On Android, while using Talkio in your browser, you will find an installation button after signing up. 
    On iOS, open Talkio in Safari, tap the share icon, and select 'Add to Home Screen' 
    to install it like a regular app on your phone.`,
  ],
  [
    "What browsers can I use?",
    `Talkio AI works in all modern browsers, including Chrome, Firefox, Safari and Edge.
    It also works on mobile browsers on iPhone and Android.
    `,
  ],
  [
    "What AI models do you use?",
    `Talkio AI uses a mix of different AI models from OpenAI, Anthropic and Meta. 
    Every model has a specific task they excel at and together they create a powerful AI tutor.`,
    ,
  ],
  [
    "How is Talkio AI different from Voice Control for ChatGPT?",
    `Talkio AI offers a coherent experience with ChatGPT customized for language learning. 
    It also offers more features including pronunciation assessment, premium voice technologies, 
    multilingual support, curated training materials, integrated feedback and translations.`,
  ],
  [
    "How can I contact you?",
    `You are very welcome to write an email to hello@talkio.ai`,
  ],
];

export default function LandingFaq() {
  return (
    <Accordion>
      {faqItems.map(([question, answer], index) => (
        <AccordionItem key={index}>
          <Heading size="xs">
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left" fontWeight={"bold"}>
                {typeof question === "function" ? question() : question}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel pb={4}>
            {typeof answer === "function" ? answer() : answer}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
}

function GoodForBeginners() {
  return (
    <>
      <Text>
        Talkio AI is best suited for those with a basic knowledge of a language,
        aiming to improve their oral skills. For beginners, we have developed an
        introductory guide to get you started. This guide covers fundamental
        aspects of your chosen language, preparing you to fully benefit from
        Talkio AI. It is free and a great tool for learners starting their
        journey. Check out the guide and begin enhancing your language skills
        effectively.
      </Text>
      <Button
        my={3}
        colorScheme="brand.primary"
        color="brand.gray.700"
        size="xs"
        px={5}
        href="/guide/beginner-intro"
        as="a"
        target="_blank"
      >
        Try the FREE getting started guide
      </Button>
    </>
  );
}

function FaqMe() {
  return (
    <>
      <Text>
        Talkio AI is a product of{" "}
        <StdLink
          target="_blank"
          href="https://datacvr.virk.dk/enhed/virksomhed/43598171?fritekst=43598171&sideIndex=0&size=10"
          text="Aidia ApS"
          rel="nofollow"
        />{" "}
        listed in the European Union. Aidia ApS complies to the General Data
        Protection Regulation (GDPR) in order to deliver a safe and secure
        experience for all users.
      </Text>
    </>
  );
}
