import { Text, Image, Box, Flex, Button, Divider } from "@chakra-ui/react";
import FeatureCheckList from "./feature-check-list";
import { Section } from "./section";

interface Props {
  onButtonClick: () => void;
}

type Feature = {
  image: string;
  title: string;
  bullets: string[];
  imageSide: "left" | "right";
};

const features: Feature[] = [
  {
    image: "/images/landing/chat-1.jpeg",
    title: "Practice speaking",
    bullets: [
      "Life-like conversations with an AI tutor",
      "Voice-First Interaction",
      "Conversation Help",
      "Repeat & Retry",
    ],
    imageSide: "left",
  },
  {
    image: "/images/landing/chat-cn.jpeg",
    title: "A World of Languages",
    bullets: [
      "Supports 40+ Languages",
      "Instant translations",
      "Multilingual Speech Recognition",
      "Romanization of Script Languages",
    ],
    imageSide: "right",
  },
  {
    image: "/images/landing/feedback.jpeg",
    title: "Actionable Feedback",
    bullets: [
      "Sentence Scoring",
      "Improvement Strategies",
      "AI Suggestions",
      "Speech Precision Score",
    ],
    imageSide: "left",
  },
  {
    image: "/images/landing/pronunciation.jpeg",
    title: "Pronunciation Practice",
    bullets: [
      "Word-by-word Score",
      "Pronunciation Assessment",
      "Accuracy, Completeness & Fluency",
      "Repeat & Retry",
    ],
    imageSide: "right",
  },
  {
    image: "/images/landing/wordbook.jpeg",
    title: "Interactive Wordbook",
    bullets: [
      "Keep Track of the Words You Learn",
      "Reinforced Memorization via Tutor Repetition",
      "Translation & Read-Aloud",
      "Sentence Examples",
    ],
    imageSide: "right",
  },
];

export default function FeaturePresentation(props: Props) {
  return (
    <Box>
      {features.map((feature, index) => (
        <Box
          key={index}
          backgroundColor={feature.imageSide === "right" ? "white" : "white"}
        >
          <Section>
            <Flex
              mx="auto"
              my={{ base: 0, lg: 12 }}
              flexDir={{
                base: "column",
                lg: feature.imageSide === "right" ? "row-reverse" : "row",
              }}
              alignItems={{ lg: "center" }}
            >
              <Box flex="1" maxW={{ base: "100%", lg: "60%" }} px={5}>
                <Image
                  borderRadius={"lg"}
                  boxShadow={"lg"}
                  src={feature.image}
                  alt={feature.title}
                  w="full"
                  maxW="600px"
                />
              </Box>
              <Flex
                flex="1"
                maxW={{ base: "100%", lg: "40%" }}
                py={{ base: 5, lg: 0 }}
                px={5}
                flexDir="column"
              >
                <Text
                  fontWeight={"bold"}
                  lineHeight="1.2"
                  fontSize="4xl"
                  as="h3"
                >
                  {feature.title}
                </Text>

                <Box>
                  <FeatureCheckList
                    my={5}
                    fontSize="md"
                    checkMarkColor={"#000"}
                    features={feature.bullets}
                    spacing={6}
                    listItemSpacing={2}
                  />

                  <Button
                    size="md"
                    mt={2}
                    variant="outline"
                    colorScheme="brand.green"
                    borderColor="brand.green.500"
                    color="brand.green.500"
                    onClick={props.onButtonClick}
                  >
                    Start Your Free Trial
                  </Button>
                </Box>
              </Flex>
            </Flex>
          </Section>
          <Divider maxW="1000px" m="0 auto" />
        </Box>
      ))}
    </Box>
  );
}
